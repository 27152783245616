// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

//
// Color system
//

// stylelint-disable
$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #e5e5e5;
$gray-300: #999;
$gray-400: #888;
$gray-500: #777;
$gray-600: #555;
$gray-700: #444;
$gray-800: #333;
$gray-900: #151515;
$black: #000;

$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$blue: #008dff;
$grey: #575158;
$dark-blue: #007ee5;
$indigo: #6610f2;
$purple: #4829b2;
$pink: #e83e8c;
$red: #fc2c38;
$orange: #f3a83c;
$yellow: #ddd44b;
$green: #68da84;
$teal: #20c997;
$cyan: #17a2b8;
$rose: #af5d6a;
$transparent-white: rgba($white, 0.2);
$transparent-black: rgba($black, 0.2);

$colors: ();
$colors: map-merge(
  (
    "blue": $blue,
    "dark-blue": $dark-blue,
    "indigo": $indigo,
    "purple": $purple,
    "pink": $pink,
    "red": $red,
    "orange": $orange,
    "yellow": $yellow,
    "green": $green,
    "teal": $teal,
    "cyan": $cyan,
    "white": $white,
    "gray": $gray-600,
    "gray-dark": $gray-800,
    "transparent-white": $transparent-white,
    "transparent-black": $transparent-black
  ),
  $colors
);

$primary: $blue;
$secondary: $gray-100;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

$theme-colors: ();
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark
  ),
  $theme-colors
);
// stylelint-enable

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% !default;

// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 150 !default;

// Customize the light and dark text colors for use in our YIQ color contrast function.
$yiq-text-dark: $gray-900 !default;
$yiq-text-light: $white !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default; // Deprecated, no longer affects any compiled CSS
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// stylelint-disable
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.625
    ),
    2: (
      $spacer * 1.25
    ),
    3: (
      $spacer * 1.875
    ),
    4: (
      $spacer * 2.5
    ),
    5: (
      $spacer * 3.125
    ),
    6: (
      $spacer * 3.75
    ),
    7: (
      $spacer * 4.375
    ),
    8: (
      $spacer * 5
    ),
    9: (
      $spacer * 5.625
    ),
    10: (
      $spacer * 6.25
    )
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    20: 20%,
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%
  ),
  $sizes
);
// stylelint-enable

// Body
//
// Settings for the `<body>` element.

$body-bg: $white;
$body-color: $gray-900;

// Links
//
// Style anchor elements.

$link-color: theme-color("primary") !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.

// $paragraph-margin-bottom:   1rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1200px,
  xl: 1600px
);

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 1170px,
  xl: 1300px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 30px !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: 1.5 !default;
$line-height-sm: 1.5 !default;

$border-width: 1px !default;
$border-color: $gray-200;

$border-radius: 0.25rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.2rem !default;

$component-active-color: $white !default;
$component-active-bg: theme-color("primary") !default;

$caret-width: 0.3em !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity 0.15s linear !default;
$transition-collapse: height 0.35s ease !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: "Rubik", sans-serif;
$font-family-monospace: "Roboto Mono", monospace;
$font-family-base: $font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.125);
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$h1-font-size: $font-size-base * 3.125; // 50px
$h2-font-size: $font-size-base * 2.5; // 40px
$h3-font-size: $font-size-base * 2; // 32px
$h4-font-size: $font-size-base * 1.75;
$h5-font-size: $font-size-base * 1.5;
$h6-font-size: $font-size-base;

$headings-margin-bottom: 1em;
$headings-font-family: "Nunito Sans", sans-serif;
$headings-font-weight: 300;
$headings-line-height: 1.25;
$headings-color: inherit;

$display1-size: 5rem; // 80px
$display2-size: 4.375rem; // 70px
$display3-size: 3.75rem; // 60px
$display4-size: 3.4375rem; // 55px

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-400;

$blockquote-small-color: $gray-400;
$blockquote-font-size: ($font-size-base * 1.25) !default;

$hr-border-color: rgba($black, 0.1) !default;
$hr-border-width: $border-width !default;

$mark-padding: 0.2em !default;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fcf8e3 !default;

$hr-margin-y: $spacer !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 1.875rem;
$table-cell-padding-sm: 0.9375rem;

$table-bg: transparent !default;
$table-accent-bg: rgba($black, 0.05) !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $gray-200;

$table-head-bg: $gray-200 !default;
$table-head-color: $gray-700 !default;

$table-dark-bg: $gray-900 !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($gray-900, 7.5%) !default;
$table-dark-color: $body-bg !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.9375rem; // 15px
$input-btn-padding-x: 1.875rem; // 30px
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0;
$input-btn-focus-color: transparent;
$input-btn-focus-box-shadow: 0;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 1rem;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 1.1rem;
$input-btn-padding-x-lg: 2.2rem;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: $border-width;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y !default;
$btn-padding-x: $input-btn-padding-x !default;
$btn-line-height: $input-btn-line-height !default;

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: $input-btn-border-width !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-disabled-color: $gray-600 !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

// Forms

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: $input-btn-padding-y;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-y-sm;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-y-lg;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white;
$input-disabled-bg: rgba($black, 0.1);

$input-color: $gray-900;
$input-border-color: $border-color;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;

$input-border-radius: $border-radius !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($component-active-bg, 25%) !default;
$input-focus-color: $input-color !default;
$input-focus-width: $input-btn-focus-width !default;
$input-focus-box-shadow: $input-btn-focus-box-shadow !default;

$input-placeholder-color: $gray-300;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: ($font-size-base * $input-btn-line-height) +
  ($input-btn-padding-y * 2) !default;
$input-height: calc(#{$input-height-inner} + #{$input-height-border}) !default;

$input-height-inner-sm: ($font-size-sm * $input-btn-line-height-sm) +
  ($input-btn-padding-y-sm * 2) !default;
$input-height-sm: calc(
  #{$input-height-inner-sm} + #{$input-height-border}
) !default;

$input-height-inner-lg: ($font-size-lg * $input-btn-line-height-lg) +
  ($input-btn-padding-y-lg * 2) !default;
$input-height-lg: calc(
  #{$input-height-inner-lg} + #{$input-height-border}
) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-text-margin-top: 0.25rem !default;

$form-check-input-gutter: 1.25rem !default;
$form-check-input-margin-y: 0.3rem !default;
$form-check-input-margin-x: 0.25rem !default;

$form-check-inline-margin-x: 0.75rem !default;
$form-check-inline-input-margin-x: 0.3125rem !default;

$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: $border-color;
$input-group-addon-border-color: transparent;

$custom-control-gutter: 1.5rem !default;
$custom-control-spacer-x: 1rem !default;

$custom-control-indicator-size: 1rem !default;
$custom-control-indicator-bg: $gray-300 !default;
$custom-control-indicator-bg-size: 75% 75%;
$custom-control-indicator-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

$custom-control-indicator-disabled-bg: $gray-200 !default;
$custom-control-label-disabled-color: $gray-600 !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg: rgba(
  theme-color("primary"),
  0.5
) !default;
$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-btn-focus-box-shadow !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten(
  $component-active-bg,
  35%
) !default;
$custom-control-indicator-active-box-shadow: none !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='4' stroke-linecap='round' stroke-linejoin='round' %3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E"),
  "#",
  "%23"
);

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

$custom-select-padding-y: 0.375rem !default;
$custom-select-padding-x: 0.75rem !default;
$custom-select-height: $input-height !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
$custom-select-line-height: $input-btn-line-height !default;
$custom-select-color: $input-color !default;
$custom-select-disabled-color: $gray-600 !default;
$custom-select-bg: $white !default;
$custom-select-disabled-bg: $gray-200 !default;
$custom-select-bg-size: 8px 10px !default; // In pixels because image dimensions
$custom-select-indicator-color: $gray-800 !default;
$custom-select-indicator: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$custom-select-border-width: $input-btn-border-width !default;
$custom-select-border-color: $input-border-color !default;
$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: $input-focus-border-color !default;
$custom-select-focus-box-shadow: inset 0 1px 2px rgba($black, 0.075),
  0 0 5px rgba($custom-select-focus-border-color, 0.5) !default;

$custom-select-font-size-sm: 75% !default;
$custom-select-height-sm: $input-height-sm !default;

$custom-select-font-size-lg: 125% !default;
$custom-select-height-lg: $input-height-lg !default;

$custom-file-height: $input-height !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-btn-focus-box-shadow !default;

$custom-file-padding-y: $input-btn-padding-y !default;
$custom-file-padding-x: $input-btn-padding-x !default;
$custom-file-line-height: $input-btn-line-height !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-btn-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
  en: "Browse"
) !default;

// Form validation
$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $small-font-size !default;
$form-feedback-valid-color: theme-color("success") !default;
$form-feedback-invalid-color: theme-color("danger") !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 10rem !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-spacer: 0.125rem !default;
$dropdown-bg: $white !default;
$dropdown-border-color: transparent;
$dropdown-border-radius: $border-radius !default;
$dropdown-border-width: 0;
$dropdown-divider-bg: $gray-200 !default;
$dropdown-box-shadow: 0 0.5rem 1rem rgba($black, 0.175) !default;

$dropdown-link-color: $gray-300;
$dropdown-link-hover-color: darken($gray-900, 5%) !default;
$dropdown-link-hover-bg: $gray-100 !default;

$dropdown-link-active-color: $component-active-color !default;
$dropdown-link-active-bg: $component-active-bg !default;

$dropdown-link-disabled-color: $gray-600 !default;

$dropdown-item-padding-y: 0.625rem; // 10px
$dropdown-item-padding-x: 1.25rem; // 20px

$dropdown-header-color: $gray-600 !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

// Navs

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-disabled-color: $gray-600 !default;

$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: transparent;

// Navbar

$navbar-padding-y: ($spacer / 2) !default;
$navbar-padding-x: $spacer;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// // Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: (
  $font-size-base * $line-height-base + $nav-link-padding-y * 2
) !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2 !default;

$navbar-toggler-padding-y: 0;
$navbar-toggler-padding-x: 0;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: 0;

$navbar-dark-color: $white;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: rgba($black, 0.5) !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

// Pagination

$pagination-padding-y: 0.5rem !default;
$pagination-padding-x: 0.75rem !default;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-color: $link-color !default;
$pagination-bg: $white !default;
$pagination-border-width: 0;
$pagination-border-color: transparent;

$pagination-focus-box-shadow: $input-btn-focus-box-shadow !default;

$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-200 !default;
$pagination-hover-border-color: $gray-300 !default;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: $gray-600 !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: $gray-300 !default;

// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-bg: $gray-200 !default;

// Cards

$card-spacer-y: 0.9375rem; // 15px
$card-spacer-x: 1.875rem; // 30px
$card-border-width: 0;
$card-border-radius: $border-radius !default;
$card-border-color: $border-color;
$card-inner-border-radius: $border-radius;
$card-cap-bg: transparent;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: ($grid-gutter-width / 2) !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: $font-size-sm !default;
$tooltip-max-width: 200px !default;
$tooltip-color: $white !default;
$tooltip-bg: $black !default;
$tooltip-border-radius: $border-radius !default;
$tooltip-opacity: 0.9 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.5rem !default;
$tooltip-margin: 0 !default;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Badges

$badge-font-size: 1em;
$badge-font-weight: $font-weight-normal;
$badge-padding-y: 0.5rem;
$badge-padding-x: 1rem;
$badge-border-radius: $border-radius !default;

$badge-pill-padding-x: $badge-padding-x;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
$badge-pill-border-radius: 10rem !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1.875rem; // 30px

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: 0;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1.875rem; // 30px

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $card-spacer-x;
$alert-padding-x: $card-spacer-x;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: 0;

$alert-bg-level: -4;
$alert-border-level: -9 !default;
$alert-color-level: 8;

// Progress bars

$progress-height: 0.5rem;
$progress-font-size: ($font-size-base * 0.75) !default;
$progress-bg: rgba($black, 0.1);
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: theme-color("primary");
$progress-bar-bg: theme-color("primary") !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// List group

$list-group-bg: transparent;
$list-group-border-color: $gray-200;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 1.25rem;
$list-group-item-padding-x: 1.25rem;

$list-group-hover-bg: $gray-100 !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: $gray-600 !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: $gray-300;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $gray-200 !default;

// Image thumbnails

$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: $gray-300 !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;

// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: $gray-600 !default;

// Breadcrumbs

$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: 0.5rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-600 !default;
$breadcrumb-active-color: $gray-600 !default;
$breadcrumb-divider: "/" !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;
$carousel-control-next-icon-bg: str-replace(
  url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"),
  "#",
  "%23"
) !default;

$carousel-transition: transform 0.6s ease !default;

// Close

$close-font-size: $font-size-base * 1.5 !default;
$close-font-weight: $font-weight-bold !default;
$close-color: $black !default;
$close-text-shadow: 0 1px 0 $white !default;

// Code

$code-font-size: 87.5% !default;
$code-color: $pink !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: $gray-900 !default;

$pre-color: $gray-900 !default;
$pre-scrollable-max-height: 340px !default;

// Printing
$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;

.btn-primary {
  color: #fff !important;
  background-color: $primary !important;
  border-color: $primary !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: $dark-blue !important;
  border-color: $primary !important;
}
.text-rose {
  color: $rose !important;
}
.bg-grey {
  background-color: $grey !important;
}
.bg-black {
  background-color: #000 !important;
}

.project-overlay {
  -webkit-transition: opacity 0.5s; /* Safari */
  transition: opacity 0.5s;
}
.project-overlay:hover {
  opacity: 0;
}
.palette-square {
  height: 100px;
  width: 100px;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.project-item {
  border-radius: 8px;
  overflow: hidden;
}

.cover-title {
  margin-bottom: 5px;
  font-weight: bold;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

::selection {
  background-color: $primary;
  color: white;
}

// CUSTOM

.cover-subtitle {
  margin-top: 20px;
}

.cover-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cover-content > .cover-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cover-content > .cover-block-image {
  background-image: url("../images/magnify-apps-cover-S.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vw;
  width: 100vw;
  margin-top: 30px;
}

.cover-content > .cover-block-text {
  justify-content: center;
  align-items: center;
}

.cover-content {
  padding-top: 100px;
}

.mg-header {
  z-index: 200;
}

.cover-title {
  margin-bottom: 5px;
  font-weight: bold;
}

.btn-outline-primary:hover {
  color: #fff !important;
}

::selection {
  background-color: #008dff;
  color: white;
}

.cover-subtitle {
  margin-top: 20px;
}

.cover-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.cover-content > .cover-block {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cover-content > .cover-block-image {
  background-image: url("../images/magnify-apps-cover-S.png");
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vw;
  width: 100vw;
  margin-top: 30px;
  opacity: 0.9;
}

.cover-content > .cover-block-text {
  justify-content: center;
  align-items: center;
  padding: 15px;
}

.cover-content {
  padding-top: 100px;
}

.mg-header {
  z-index: 200;
}

.cover-underlay {
  top: 0;
  left: calc(-1 * 115em + 48%);
  position: absolute;
  background-image: url("../images/underlay.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 2000px;
  height: 200vh;
  z-index: 100;
  pointer-events: none;
}

.bubble-right {
  top: 0;
  right: 0;
  position: absolute;
  width: 600px;
  height: 200vh;
  background-image: url("../images/bubble.svg");
  background-position: top right;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
  pointer-events: none;
}

.underlay-bottom-left {
  position: fixed;
  bottom: 0;
  left: -135px;
  width: 80vw;
  height: 500px;
  background-image: url("../images/product-underlay-flip.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scaleX(-1);
  z-index: 100;
  pointer-events: none;
}

.underlay-bottom-right {
  position: fixed;
  bottom: 0;
  right: -135px;
  width: 80vw;
  height: 500px;
  background-image: url("../images/product-underlay.svg");
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: contain;
  transform: scaleX(-1);
  z-index: 100;
  pointer-events: none;
}

.products-underlay {
  background-image: url("../images/product-underlay.svg");
  background-position: bottom right;
  background-repeat: no-repeat;
  background-size: contain;
  width: 1000px;
  height: 300px;
  position: absolute;
  bottom: 0;
  right: -11em;
}

::placeholder {
  color: white !important;
}

.mg-feature-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mg-feature-box-content {
  flex: 1;
  padding-bottom: 20px;
}

.feature-image {
  max-width: 400px;
  width: 100%;
}

.feature-image-L {
  max-width: 500px;
  width: 100%;
}

.slider-screen {
  width: calc(767px / 3);
}

.product-button {
  flex-shrink: 1;
  cursor: -webkit-grab;
  cursor: grab;
  padding: 10px 10px;
  border-radius: 10px;
  margin: 5px 10px;
}

.product-button:hover {
  background-color: #ececec;
}

.product-button-container > .active {
  background-color: $primary;
  color: white;
}

.product-button-container > .active:hover {
  background-color: $dark-blue;
}

.mg-product-text {
  display: none;
}

.mg-product-text-container {
  padding: 4em 10px 2em 10px;
}

.mg-product-text-container > .active {
  display: block;
}

.products-wrapper {
  display: flex;
  flex-direction: column;
}

.product-pics-container > .active {
  display: block !important;
}

.product-pics-container > .owl-carousel {
  display: none;
}

.product-pics-container {
  height: 550px;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.mg-carousel {
  height: 600px;
  width: 800px;
  position: absolute;
  left: 50%;
  margin-left: -400px;
  top: 15px;
}

.mg-carousel-phone {
  width: 268px;
  position: absolute;
  top: -1;
  left: 50%;
  margin-left: calc(268px / -2);
}

html,
body {
  overflow-x: hidden;
}
body {
  position: relative;
}

@media only screen and (min-width: 768px) {
  .sm-steps {
    display: none;
  }

  .products-wrapper {
    flex-direction: row;
  }

  .mg-product-text-container {
    padding: 40px 5% 40px 80px;
  }
}

@media only screen and (min-width: 768px) {
  .cover-button-mobile {
    display: none !important;
  }
  .cover-content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
    color: #ffffff;
  }
  .cover-content > b {
    color: #ffffff;
  }
  .cover-content > .cover-block {
    height: 100vh;
    flex: 1;
  }
  .cover-content .cover-block-image {
    margin-top: 0;
    background-position: center;
  }
  .cover-content > .cover-block-text {
    z-index: 150;
  }
}

@media only screen and (max-width: 400px) {
  .cover-subtitle {
    font-size: 1.3em;
  }
  .cover-title {
    font-size: 2em;
  }

  .mg-product-text-container {
    flex-direction: column;
    align-items: center;
    display: flex;
  }

  .mg-product-text-container > .btn {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .products-underlay {
    display: none;
  }

  .cover-underlay {
    display: none;
  }

  .cover-button-desktop {
    display: none !important;
  }

  .navbar-logo {
    width: 150px !important;
  }

  .product-button > h5 {
    font-size: 15px;
  }

  .product-button > h5 {
    font-size: 15px;
  }
}

@media only screen and (min-width: 768px) {
  .sm-steps {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .cover-content {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: visible;
    color: #ffffff;
  }
  .cover-content > b {
    color: #ffffff;
  }
  .cover-content > .cover-block {
    height: 100vh;
    flex: 1;
  }
  .cover-content .cover-block-image {
    margin-top: 0;
    background-position: center;
  }
  .cover-content > .cover-block-text {
    z-index: 150;
  }
  .cover-underlay {
  }
}

@media only screen and (min-width: 1600px) {
  .cover-content > .cover-block-image {
    background-image: url("../images/magnify-apps-cover-L.png");
  }
}

@media only screen and (min-width: 400px) {
  .cover-content > .cover-block-image {
    background-image: url("../images/magnify-apps-cover-M.png");
  }
}

@media only screen and (max-width: 400px) {
  .cover-subtitle {
    font-size: 1.3em;
  }
}
